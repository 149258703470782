import styles from "./Navbar.module.scss";
import lemonOdyssey from "../assets/lemon_odyssey.png";

const Navbar = () => {
  return (
    <div className={styles.container}>
      <div className="hide-sm">
        <p className="glitch" data-text="join the most epic">join the most epic</p>
        <p className="glitch" data-text="battle in the history of the galaxy.">battle in the history of the galaxy.</p>
        <p className="glitch" data-text="The future lays in your hands.">The future lays in your hands.</p>
      </div>
      <img src={lemonOdyssey} alt="" className={styles.logo} />
      <span className="glitch hide-sm" data-text="The revolution is here.">The revolution is here.</span>
    </div>
  );
};

export default Navbar;
