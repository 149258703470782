import { useEffect, useRef } from "react";
import styles from "./Story.module.scss";

const resetAnimation = () => {
  var el = document.getElementById("text-body");
  el.classList.remove(styles.animated);
  setTimeout(() => {
    el.classList.add(styles.animated);
  });
};

const Story = () => {
  const textRef = useRef();
  useEffect(() => {
    textRef.current.style.animationPlayState = "paused";
    function handleScroll() {
      if (textRef.current) {
        const position = textRef.current.getBoundingClientRect();
        if (position.top < window.innerHeight && position.bottom >= 0) {
          textRef.current.style.animationPlayState = "running";
        }
      }
    }
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.addEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.story}>
        <button onClick={resetAnimation} className="glitch" data-text="↻ Reset">
          ↻ Reset
        </button>
        <span className="glitch" data-text="Story:">
          Story:
        </span>
      </div>
      <div className={styles.fade}></div>
      <section className={styles.starWars}>
        <div
          className={`${styles.crawl} ${styles.animated}`}
          ref={textRef}
          id="text-body"
        >
          <div className={styles.title}>
            <h1>Lemon Odyssey</h1>
          </div>
          <p className={styles.paragraph}>
            Decades ago, the meteorite wandered in space, spinning and traveling
            in the void. It crossed multiple galaxies, going unnoticed by
            thousands of civilizations, until it crossed Boolant, a minor planet
            whose history would be drastically altered.
          </p>
          <p className={styles.paragraph}>
            The strange object sank into the earth by the force of the impact,
            and from its interior a mysterious juice poured down to the surface
            and generated subterranean rivers, renewing the life that ran
            through the veins of the planet.
          </p>
          <p className={styles.paragraph}>
            From the open cracks of the meteorite descended beings, the Lemmys,
            who came to bring freedom to an oppressed world.
          </p>
          <p className={styles.paragraph}>
            This is the beginning of a struggle for power, honor and the future
            of the galaxy, and it is only one of the many stories of the Lemon
            Odyssey.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Story;
