import coinbase from "../assets/coinbase.svg";
import valor from "../assets/valor.svg";
import kingsway from "../assets/kingsway.svg";
import draper from "../assets/draper-associates.svg";
import styles from "./About.module.scss";
import { Link } from "react-router-dom";

function About() {
  return (
    <div className={`${styles.container} ${styles.background}`}>
      <div data-aos="fade-up" className={`${styles.back} hide-sm`}>
        <Link to="/">
          <span className="glitch" data-text="← Back">← Back</span>
        </Link>
      </div>
      <div data-aos="fade-up" className={styles.poweredBy}>
        <div>
          <p
            className="glitch"
            data-text="Lemon is a Crypto Fintech that is launching"
          >
            Lemon is a Crypto Fintech that is launching
          </p>
          <p
            className="glitch"
            data-text="civilization into the future of money."
          >
            civilization into the future of money.
          </p>
          <p
            className="glitch"
            data-text="Odyssey is the first step into descentralization for Lemon."
          >
            Odyssey is the first step into descentralization for Lemon.
          </p>
        </div>
      </div>
      <div className={styles.sponsorsContainer}>
        <div>
          <span
            className="glitch"
            data-aos="fade-up"
            data-text="Lemon Cash is backed by:"
          >
            Lemon Cash is backed by:
          </span>
        </div>
        <img
          src={kingsway}
          data-aos="fade-up"
          data-aos-delay="100"
          className={styles.sponsor}
          alt=""
        />
        <img
          src={draper}
          data-aos="fade-up"
          data-aos-delay="100"
          className={styles.sponsor}
          alt=""
        />
        <img
          src={coinbase}
          data-aos="fade-up"
          data-aos-delay="100"
          className={styles.sponsor}
          alt=""
        />
        <img
          src={valor}
          data-aos="fade-up"
          data-aos-delay="100"
          className={styles.sponsor}
          alt=""
        />
      </div>
    </div>
  );
}

export default About;
