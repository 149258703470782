import Story from "../components/Story";
import lancer from "../assets/lancer.gif";
import archer from "../assets/archer.gif";
import middelBackground from "../assets/middle_background.png";
import lemonLogoGame from "../assets/lemon_logo_game.png";
import lemonLogo from "../assets/lemon_logo.svg";
import { Fragment } from "react";
import Navbar from "../components/Navbar";
import styles from "./Home.module.scss";
import { Link } from "react-router-dom";
import nft1 from '../assets/exp1.png';
import nft2 from '../assets/exp2.png';
import nft3 from '../assets/exp3.png';

function Home() {
  return (
    <Fragment>
      <div className={`${styles.container} ${styles.background}`}>
        <Navbar />
        <div className={styles.characters}>
          <img src={nft3} alt="" />
          <img src={nft2} alt="" />
          <img src={nft1} alt="" />
        </div>
      </div>
      <div className={styles.container}>
        <Story />
      </div>
      <div className={styles.container}>
        <div data-aos="fade-up">
          <a
            href="https://discord.gg/CGPMPAUAne"
            target="_blank"
            rel="noreferrer"
            className={`${styles.joinDiscord} glitch`}
            data-text="Join Discord"
          >
            Join discord
          </a>
        </div>
        <img
          src={middelBackground}
          alt=""
          className={styles.discordBackground}
        />
        <img src={lemonLogoGame} alt="" className={styles.lemonGameLogo} />
      </div>
      <div className={`${styles.container} ${styles.partnership}`}>
        <div data-aos="fade-up">
          <p className="title glitch" data-text="Partnership with">
            Partnership with
          </p>
          <img
            src="https://dystopunksv2.com/static/media/logo.06a1f605.gif"
            height="100px"
            alt=""
          />
        </div>
        <div>
          <img src={lancer} alt="" className={`${styles.nft} hide-sm`} />
          <img src={archer} alt="" className={`${styles.nft} hide-sm`} />
        </div>
      </div>
      <div className={styles.poweredByContainer}>
        <div data-aos="fade-up" className={styles.poweredBy}>
          <span className="glitch" data-text="Powered by:">
            Powered by:
          </span>
          <img src={lemonLogo} alt="Lemon logo" width="300px" />
        </div>
        <div data-aos="fade-up">
          <Link to="/about">
            <span className="glitch" data-text="About">
              About
            </span>
          </Link>
        </div>
      </div>
    </Fragment>
  );
}

export default Home;
